<script>
import Navbar from "@/components/navbar";

export default {
  components: {
    Navbar,
  },
  data() {
    return {
      showSpinner: true,
    }
  },
  mounted() {
    setTimeout(() => {
      this.showSpinner = false;
    }, 800)
  },
}
</script>

<template>
<div>

  <header
      class="defaultscroll bg-white"
  >
    <div class="row">
      <!-- Logo container-->
      <div class="col-4">
        <router-link class="logo pl-4" to="/">
          <img :src="$store.state.style.theme === 'light' ? 'images/logo-dark.png' : 'images/logo-light.png'" height="24" alt="" />
        </router-link>
      </div>
      <div class="col-12 col-sm-4 d-flex justify-content-center align-items-center">
        <h4
            class="title pt-1 m-0"
            data-aos="fade-up"
            data-aos-duration="1000"
        >
          Contact us
        </h4>
      </div>

      <!--end navigation-->
    </div>
    <!--end container-->
  </header>
  <div>
    <!-- Hero Start -->
    <section
        class="d-flex align-items-center justify-content-center pt-4 flex-column pb-4 mt-1"
    >

      <div><p class="text-muted fs-1 p-2">To contact Stubtools support please fill out the form below.</p></div>

      <div v-if="showSpinner">
        <b-spinner variant="primary" label="Spinning"></b-spinner>
      </div>
      <div id="form-hbspt" v-show="!showSpinner">
        <script type="application/javascript">
          hbspt.forms.create({
            region: "na1",
            portalId: "22181133",
            formId: "6e5537aa-1c41-4b3a-a76e-034e625ff767"
          });
        </script>
      </div>
    </section>

  </div>
</div>
</template>

<style scoped>
#form-hbspt {
  color: white;
  background-color: whitesmoke;
  padding: 30px;
  margin: 10px 5px;
  border-radius: 10px;
  min-width: 70%;
  user-select: none;
}
</style>